.wrapper {
    padding: 2rem 0;
    display: grid;
    gap: 3rem;
    // justify-content: space-evenly;
    // justify-content: left;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 24rem));
    .card {
      background-color: var(--white);
      color: var(--gray-9);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      overflow: hidden;
      position: relative;
      box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
      border-radius: .6rem;
      z-index: 0;
      .title{
        :hover{
          color: orange;
        }
      }
    }
  
    .footer {
      padding: 1rem;
  
      .videoInfo {
        display: grid;
        grid-auto-flow: column;
        justify-content: left;
        align-items: center;
  
        span {
          font-size: 1rem;
          color: var(--gray-8);
        }
      }
  
      .title {
  
        h5 {
          font-size: 1.4rem;
        }
      }
      
  
      .actions {
        display: grid;
        justify-content: right;
        grid-auto-flow: column;
        gap: 1rem;
        opacity: 0;
  
        .btn {
          background: var(--gray-10);
          padding: 1rem 1.2rem;
          width: max-content;
          color: var(--white);
          border: none;
          justify-self: right;
  
          &:hover {
            background: var(--gray-11);
            color: var(--white);
          }
        }
      }
      &:hover {
        .actions {
            opacity: 1;
        }
    }
    }
    
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .card {
      width: calc(33.33% - 2rem);
    }
  }
  
  @media (max-width: 992px) {
    .card {
      width: calc(50% - 2rem);
    }
  
    .title h5 {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 768px) {
    .card {
      width: calc(100% - 2rem);
    }
  
    .title h5 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 1200px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(20rem, 20rem));
    }
    .card {
      min-width: 20rem;
      max-width: 20rem;
    }
  }
  
  /* For medium-large devices (desktops, 992px to 1199px) */
  @media (max-width: 992px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(18rem, 18rem));
    }
    .card {
      min-width: 18rem;
      max-width: 18rem;
    }
  }
  
  /* For medium devices (tablets, 768px to 991px) */
  @media (max-width: 768px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(16rem, 16rem));
    }
    .card {
      min-width: 16rem;
      max-width: 16rem;
    }
  }
  
  /* For small devices (phones, 576px to 767px) */
  @media (max-width: 576px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(14rem, 14rem));
    }
    .card {
      min-width: 14rem;
      max-width: 14rem;
    }
  }
  
  /* For extra small devices (phones, less than 576px) */
  @media (max-width: 575px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(12rem, 12rem));
    }
    .card {
      min-width: 12rem;
      max-width: 12rem;
    }
  }

  @media (min-width: 1400px) {
    .wrapper {
      grid-template-columns: repeat(auto-fit, minmax(23rem, 23rem));
    }
    .card {
      min-width: 23rem;
      max-width: 23rem;
    }
  }