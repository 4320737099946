.wrapper {
    min-height: 100%;
    background: var(--layout);

    .shallowHeader {
        height: 5.6rem;
        line-height: 5.6rem;
        background: transparent;
        z-index: 19;
    }

    .sectionLayout {
        background: var(--layout);
    }

    .header {
        position: fixed;
        inset-block-start: 0;
        width: 100%;
        z-index: 100;
        inset-inline-start: 0;
        inset-inline-end: 0;
        height: 5.6rem;
        line-height: 5.6rem;
        z-index: 19;
        width: 100%;
        padding-block: 0;
        padding-inline: 0;
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(8px);

        .placement {
            padding-inline-end: 1.6rem;
        }
        .branding{
            opacity: 0; /* Initially invisible */
            animation: fadeIn 1s ease-in forwards; /* Apply fade-in animation */
  
            /* Delay the animation if needed */
            // animation-delay: 0.5s;

            @keyframes fadeIn {
                 from {
                     opacity: 0;
                }
                to {
                     opacity: 1;
                }
            }
            color: darkorange;
        }

        .topNavHeader {
            position: relative;
            width: 100%;
            height: 100%;
            background: transparent;

            .topNavHeaderMain {
                display: flex;
                height: 100%;
                align-items: center;

                .topNavHeaderMainLeft {
                    padding-right: 2rem;
                    width: 7rem;
                    background: #001628;
                    .appsIcon {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding-inline: 0.4rem;
                        padding-block: 0;
                        height: 2.8rem;
                        width: 2.8rem;
                        margin-inline-end: 1.6rem;
                        margin-inline-start: -0.8rem;
                    }

                    .logo {
                        position: relative;
                        height: 5.6rem;
                        width: 7rem;
                        display: grid;
                        justify-content: center;
                    }
                }

                .topNavHeaderMenu {
                    min-width: 0;
                    align-items: center;
                    padding-inline: 0.6rem;
                    padding-block: 0.6rem;
                    line-height: 4.4rem;
                    flex: 1 1 0%;

                    ul {
                        background: transparent;
                        margin: 0;
                        display: grid;
                        grid-auto-flow: column;
                        justify-content: left;
                        gap: 0.4rem;
                        .workspace {
                            padding: 0.4rem 0;
                            cursor: pointer;
                            background: var(--hover);
                            .text {
                                color: var(--primary);
                            }
                            .workspaceData {
                                display: grid;
                                grid-auto-flow: column;
                                justify-content: center;
                                align-items: center;
                                gap: 1rem;
                                min-width: max-content;
                                max-width: 18rem;
                            }
                            .iconR {
                                padding-right: 0.8rem;
                            }
                            .iconL {
                                padding-left: 0.8rem;
                            }
                        }
                        li {
                            list-style: none;
                            padding: 0.4rem 0.8rem;
                            border-radius: 0.4rem;
                            a {
                                font-size: 1.4rem;
                                color: var(--palette-gray-8);
                                font-weight: var(--font-regular);
                                line-height: 1.56;
                                display: grid;
                                grid-auto-flow: column;
                                gap: 0.8rem;
                                justify-content: left;
                                align-items: center;
                            }
                            &:hover {
                                background: var(--hover);
                            }
                            svg {
                                fill: var(--palette-gray-8);
                            }
                        }
                        .active {
                            background: var(--active-light);
                            a {
                                .text {
                                    color: var(--primary);
                                }
                            }
                            svg {
                                fill: var(--primary);
                                path {
                                    fill: var(--primary);
                                }
                            }
                            &:hover {
                                background: var(--active-light) !important;
                            }
                        }
                    }

                    .title {
                        text-transform: capitalize;
                    }
                }

                .headerRightContent {
                    padding-inline: 6px;
                    padding-block: 6px;
                    border-radius: 6px;
                    display: flex;
                    margin-inline-end: 1.2rem;

                    .search {
                        display: flex;
                        align-items: center;

                        .searchBox {
                            border-radius: 0.4rem;
                            margin-inline-end: 1.2rem;
                            background: rgba(0, 0, 0, 0.06);
                        }
                    }

                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                        cursor: pointer;
                    }
                }

                .headerBtnRightContent {
                    padding-block: 6px;
                    border-radius: 6px;
                    display: flex;

                    button {
                        min-width: 3rem !important;
                        width: 3rem !important;
                        height: 3rem;
                    }
                }

                .headerActionsAvatar {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding-inline-start: 1.6rem;
                    padding-inline-end: 1.6rem;
                    cursor: pointer;
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }
    }

    .toolbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2rem 0;

        .buttonList {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            justify-content: right;
        }
    }
}

.divider {
    margin: 1rem 0 !important;
}

.noTop {
    padding: 0 !important;
}

.mydrawerContent {
    border-radius: 0.6rem;
    border: 1px solid var(--border);
}

.chatWrapper {
    padding: 1rem;
    background: var(--layout);
}

.settings {
    li {
        display: grid;
        grid-auto-flow: column;
        gap: 1rem;
        justify-content: left;
    }
}

.welcomeText{
    font-size: 1.6rem;
    font-weight: 600;
}

.breadWrapper {
    ol {
        align-items: center;
    }

    span {
        font-size: 1.4rem;
        text-transform: capitalize;
    }

    .lite {
        cursor: pointer;
        color: var(--palette-gray-6);

        &:hover {
            color: var(--palette-primary-darker);
        }
    }

    .dark {
        cursor: pointer;
        color: var(--palette-primary-darker);
    }
}

.sideNavWrapper {
    min-width: 7rem !important;
    max-width: 7rem !important;
    width: 7rem !important;
    flex: 0 !important;
    position: fixed !important;
    z-index: 1;
    height: 100vh;
    overflow: auto;
    box-shadow: var(--card-4);
    transition: width 0.3s ease; // Add transition for smooth width expansion

    .text {
        vertical-align: middle !important;
    }

    &:hover {
        min-width: 27rem !important; 
        max-width: 27rem !important; 
        width: 27rem !important; 
    }
}

.memu {
    background: #001628;
    padding: 3rem 1.5rem;
    
    li {
        display: grid;
        align-items: center;
        justify-items: center;
        height: 4rem;
        width: 4rem;
        border-radius: 0.4rem;
        margin-bottom: 2rem;
        background: #485065;
        transition: width 0.3s ease, box-shadow 0.3s ease;
        margin-top: 15px;
        
        svg {
            margin-top: 10px;
            margin-left: 10px;
            fill: var(--gray-1);
        }
        .item {
            margin-bottom: 2rem;
            padding: 0.3rem;
        }
        .linkItem {
            display: flex;
            align-items: center;
            text-decoration: none;
            
            svg {
              margin-right: 0.5rem;
            }
      
            .label {
              margin-left: 4rem;
              font-size: 1.6rem;
              color: var(--gray-2);
            }
          }
        &:hover .label {
            color: darkorange;
          }

        &:hover {
            background: var(--active-light);
            cursor: pointer;
            width: 4.5rem;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
            a {
                .text {
                    color: var(--primary);
                }
            }
            svg {
                fill: var(--primary);
                path {
                    fill: var(--primary);
                }
            }
        }
    }
}

.active {
    background: var(--active-light) !important;
    cursor: pointer;
    a {
        .text {
            color: var(--primary);
        }
    }
    svg {
        fill: var(--primary);
        path {
            fill: var(--primary);
        }
    }
    &:hover {
        background: var(--active-light) !important;
        width: 100px;
    }
}
