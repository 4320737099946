@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./reset.css";
@import "./override.scss";
@import "./fonts/inter.scss";
html {
  font-size: 10px;
}

html,
body,
#root {
  font-family: var(--font-family);
  display: flex;
  flex: 1 1 auto;
  min-height: 100%;
  flex-direction: column;
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  padding-top: 5.6rem;
}

.ant-tag {
  width: max-content !important;
}

.kochart {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: var(--font-family);
  position: relative;
}

.tableCard {
  padding: 2rem;
  border-radius: 0.4rem;
  background: #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: var(--card-4);
}

.dropdownOverlayClassName {
 ul{
  li{
    display: grid !important;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: left;
  }
 }
}

.fullScreenModal{
  height: calc(100vh - 15.5rem);
}


.topNav,
.sideNav {
  .ant-menu-item-selected {
    a {
      color: var(--palette-primary-darker);
      text-shadow:
        0 0 0.05rem var(--palette-primary-darker),
        0 0 0.05rem var(--palette-primary-darker);
    }
  }
}

.dataCard {
  background: var(--palette-gray-1);
  box-shadow: var(--card-4);
  border-radius: 0.4rem;
}

.jsoneditor {
  color: var(--palette-gray-8) !important;
  border: none !important;
  border-radius: 0.4rem;
  font-family: var(--font-family) !important;
  padding: 0 1rem !important;
  .jsoneditor-menu {
    background-color: var(--palette-primary-darker) !important;
    border-bottom: none !important;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    display: grid;
    grid-template-columns: repeat(2, 2.6rem) 1fr;
    justify-content: left;
    padding: 0.5rem 1rem;
    align-items: center;
    height: unset !important;
    gap: 1rem;
  }
  .jsoneditor-frame {
    border-radius: 0.4rem;
  }
  .jsoneditor-search {
    position: unset !important;
    display: grid !important;
    justify-content: right !important;
    align-items: center !important;
    grid-auto-flow: column !important;
  }
  .jsoneditor-tree-inner {
    border-radius: 0.4rem !important;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    table {
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
  .jsoneditor-navigation-bar {
    border-bottom: none !important;
    color: var(--palette-gray-7) !important;
    background-color: var(--layout) !important;
    font-family: var(--font-family) !important;
    font-size: 1.3rem;
    border: 1px solid var(--layout) !important;
  }
  .jsoneditor-contextmenu {
    // display: none !important;
  }
  .jsoneditor-selected,
  tr.jsoneditor-highlight,
  tr.jsoneditor-selected {
    background: var(--active) !important;
  }
  .jsoneditor-sort,
  .jsoneditor-transform {
    display: none !important;
  }
}

.appContainer {
  font-size: 4.8rem;
}

.ant-dropdown{
  ul{
    margin: 0 !important;
    max-height: 25rem;
    min-width: 15rem;
    overflow: auto;
    li{
      gap: .8rem;
    }
  }
}

.ant-ribbon-text {
  font-size: 1rem;
}
.newLoader{
  animation: spin 2s infinite ease-out;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.scrollable-code {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
}
.caption {
  color: #fff;
  /* font-size: 1.75em; */
  white-space: normal; /* Ensure text is on one line */
  width: 95%;
  /* Ensure text does not overflow container */
  /* overflow: hidden;  */
  display: inline-block; /* Ensure animation applies correctly */
  // height: 100%;
  align-content: center;
}

/* Controls styling */
.controls {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.animation-buttons button,
.background-buttons button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #282c34;
  color: #61dafb;
}
.animation-buttons{
  opacity: 65%;
  margin-left: 10px;
  margin-bottom: -20px;
}

.animation-buttons button:hover,
.background-buttons button:hover {
  background-color: #61dafb;
  color: #282c34;
  opacity: 200%;
}

.background-buttons button {
  padding: 0;
}

.background-buttons img {
  width: 170px;
  height: 70px;
  border-radius: 5px;
}


.animated {
  /* animation-duration: 10s;
  animation-fill-mode: both; */
}


@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes flash {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

@keyframes flip {
  0% { transform: perspective(400px) rotateY(0); }
  100% { transform: perspective(400px) rotateY(360deg); }
}


/* Animation keyframes (add keyframes for each animation) */
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 10; }
}

@keyframes flash {
  0%, 50%, 100% {
      opacity: 1;
  }
  25%, 75% {
      opacity: 0;
  }
}

@keyframes flip {
  0%, 20%, 100% {
      transform: rotateY(0);
      opacity: 0;
  }
  50% {
      transform: rotateY(180deg);
      opacity: 1;
  }
}

@keyframes rotate-in {
  from { transform: rotate(-200deg); opacity: 0; }
  to { transform: rotate(0); opacity: 1; }
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
      transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
      transform: translateX(10px);
  }
}

@keyframes roll-in {
  0% {
      opacity: 0;
      transform: translateX(-100%) rotate(-120deg);
  }
  100% {
      opacity: 1;
      transform: translateX(0) rotate(0deg);
  }
}

@keyframes slide-left {
  0% { transform: translateX(-100%); opacity: 0; }
  20%, 80% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(100%); opacity: 0; }
}

@keyframes slide-right {
  0% { transform: translateX(100%); opacity: 0; }
  20%, 80% { transform: translateX(0); opacity: 1; }
  100% { transform: translateX(-100%); opacity: 0; }
}

@keyframes swing {
  20% { transform: rotate(15deg); }
  40% { transform: rotate(-10deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-5deg); }
  100% { transform: rotate(0); }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blinkCursor {
  from { border-right-color: rgba(0, 0, 0, 0.75); }
  to { border-right-color: transparent; }
}

@keyframes zoom-in {
  from { transform: scale(0); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes bounce-in {
  from, 20%, 40%, 60%, 80%, to { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from { opacity: 0; transform: scale3d(0.3, 0.3, 0.3); }
  20% { transform: scale3d(1.1, 1.1, 1.1); }
  40% { transform: scale3d(0.9, 0.9, 0.9); }
  60% { opacity: 1; transform: scale3d(1.03, 1.03, 1.03); }
  80% { transform: scale3d(0.97, 0.97, 0.97); }
  to { opacity: 1; transform: scale3d(1, 1, 1); }
}

@keyframes slideInBottom {
  0% { transform: translateY(100%); opacity: 0; }
  20%, 80% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(-100%); opacity: 0; }
}

@keyframes slideDown {
  0% {
      transform: translateY(-100%);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

/* Apply animation class */
.animated {
  animation-duration: 2s;
  /* animation-fill-mode: both; */
}


/* ---- */



@keyframes softTyping {
  from { width: 0; opacity: 0; }
  to { width: 100%; opacity: 1; }
}

@keyframes fadeRightTyping {
  from { width: 0; opacity: 0; transform: translateX(-10px); }
  to { width: 100%; opacity: 1; transform: translateX(0); }
}

@keyframes blinkCursor {
  from { border-right-color: rgba(0, 0, 0, 0.75); }
  to { border-right-color: transparent; }
}

@keyframes fadeScale {
  0%, 100% { 
      opacity: 0;
      transform: scale(0.5);
  }
  50% { 
      opacity: 1;
      transform: scale(1);
  }
}


@keyframes squeeze {
  0%, 100% {
      transform: scale(1);
      opacity: 0;
  }
  50% {
      transform: scale(0.8);
      opacity: 1;
  }
}


@keyframes blurFade {
  0%, 100% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
      opacity: 0.5; /* Adjust text opacity here */
  }
  50% {
      text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
      opacity: 1;
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}


@keyframes typer {
  0% { width: 0; border-color: transparent; }
  5% { width: 1ch; border-color: black; }
  10% { width: 2ch; border-color: transparent; }
  15% { width: 3ch; border-color: black; }
  20% { width: 4ch; border-color: transparent; }
  25% { width: 5ch; border-color: black; }
  30% { width: 6ch; border-color: transparent; }
  35% { width: 7ch; border-color: black; }
  40% { width: 8ch; border-color: transparent; }
  45% { width: 9ch; border-color: black; }
  50% { width: 10ch; border-color: transparent; }
  55% { width: 11ch; border-color: black; }
  60% { width: 12ch; border-color: transparent; }
  65% { width: 13ch; border-color: black; }
  70% { width: 14ch; border-color: transparent; }
  75% { width: 15ch; border-color: black; }
  80% { width: 16ch; border-color: transparent; }
  85% { width: 17ch; border-color: black; }
  90% { width: 18ch; border-color: transparent; }
  95% { width: 19ch; border-color: black; }
  100% { width: 20ch; border-color: black; }
}


@keyframes flipInX {
  0% {
      transform: perspective(400px) rotateX(90deg);
      opacity: 0;
  }
  40% {
      transform: perspective(400px) rotateX(-20deg);
  }
  60% {
      transform: perspective(400px) rotateX(10deg);
  }
  80% {
      transform: perspective(400px) rotateX(-5deg);
  }
  100% {
      transform: perspective(400px) rotateX(0);
      opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% { transform: translateX(100%) skewX(-30deg); opacity: 0; }
  60% { transform: skewX(20deg); opacity: 1; }
  80% { transform: skewX(-5deg); opacity: 1; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes zoomOut {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.5);
  }
  100% {
      transform: scale(1);
  }
}


@keyframes glitch {
  0% {
      text-shadow: 2px 2px red, -2px -2px blue;
      transform: skew(0.5deg, 0.5deg);
  }
  10% {
      text-shadow: 2px 2px blue, -2px -2px red;
      transform: skew(0.5deg, -0.5deg);
  }
  20% {
      text-shadow: -2px -2px blue, 2px 2px red;
      transform: skew(-0.5deg, 0.5deg);
  }
  30% {
      text-shadow: 2px -2px red, -2px 2px blue;
      transform: skew(-0.5deg, -0.5deg);
  }
  40% {
      text-shadow: -2px 2px blue, 2px -2px red;
      transform: skew(0.5deg, 0.5deg);
  }
  50% {
      text-shadow: 2px 2px red, -2px -2px blue;
      transform: skew(0.5deg, -0.5deg);
  }
  60% {
      text-shadow: 2px -2px blue, -2px 2px red;
      transform: skew(-0.5deg, 0.5deg);
  }
  70% {
      text-shadow: -2px -2px blue, 2px 2px red;
      transform: skew(-0.5deg, -0.5deg);
  }
  80% {
      text-shadow: 2px 2px red, -2px -2px blue;
      transform: skew(0.5deg, 0.5deg);
  }
  90% {
      text-shadow: 2px -2px blue, -2px 2px red;
      transform: skew(0.5deg, -0.5deg);
  }
  100% {
      text-shadow: -2px 2px blue, 2px -2px red;
      transform: skew(-0.5deg, 0.5deg);
  }
}



@keyframes fadeRight {
  from {
      opacity: 0;
      transform: translateX(-20px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes glow {
  0% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  50% {
      text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
}
pre {
  /* padding: 0; */
  margin: 0;
}

pre > div {
  /* padding: 1rem !important; */
  line-height: 0 !important;
}
pre > div > code {
  font-size: 1.5rem!important;
  padding: 2px !important;
  line-height: 1.5!important;
}

.container > div > p {
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
}