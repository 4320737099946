.wrapper {
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: 1fr 46rem;
    border-radius: .6rem;
    background: #F4F5FC;
    gap: 2rem;
    overflow: auto;

    .contnetSection {
        width: calc(100vw - 60rem);

        .scenesList {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            width: calc(100vw - 60rem);
            // overflow: auto;
            overflow-y: hidden;
            margin-top: 2rem;

            .sceneWrapper {
                // text-align: center;
                display: grid;
                cursor: pointer;
                gap: 1rem;
                position: relative;

                .sceneText {
                    text-align: 'center';

                    :hover {
                        color: #f47600;
                    }

                    padding-left: 50px;
                }

                .imageContainer {
                    position: relative;
                    display: inline-block;
                    /* Adjust based on your layout */
                }

                .imageContainer img {
                    display: block;
                    /* Removes extra space below the image */
                    // width: 100%; /* Ensure image scales properly */
                }

                .centeredText {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    // color: white; /* Adjust text color for visibility */
                    font-size: 8px;
                    /* Adjust text size */
                    // font-weight: bold; /* Adjust text weight */
                    text-align: center;
                    pointer-events: none;
                    /* Prevent text from interfering with clicks */
                }
            }

            .scenes {
                height: 9.25rem;
                width: 16rem;
                border: 1px solid var(--gray-1);
                border-radius: .6rem;
                padding: .3rem;

                .closeIconWrapper {
                    position: absolute;
                    top: 7px;
                    left: 140px;
                }

                .closeButton {
                    background: #ff0000;
                    height: 23px;
                    width: 23px;
                    border: none;
                    cursor: pointer;
                    color: white;
                    font-size: 1em;
                    padding: 5px;
                    border-radius: 50%;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
                    transition: all 0.3s ease;
                    margin-top: 22px;
                    margin-right: -13px;
                    z-index: 999999;

                    &:hover {
                        transform: scale(1.1);
                        background-color: #cc0000;
                        opacity: 1;
                    }

                    &:focus {
                        animation: pulse 2s infinite;
                    }
                }

                @keyframes pulse {

                    0%,
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }

                    50% {
                        transform: scale(1.1);
                        opacity: 0.7;
                    }
                }

                img {
                    border-radius: .6rem;
                    height: 8.5rem;
                    width: 16.5rem;
                }

                &:hover {
                    border: 1px solid var(--gray-8);
                    cursor: pointer;
                }
            }
        }

        .sceneAdd {
            text-align: center;
            display: grid;
            gap: 1rem;
            position: relative;
            align-items: center;
            margin-left: 1rem;
            padding-top: 2rem;

        }

        .addNewSceneButton {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fa861c;
            color: white;
            font-weight: bold;
            padding: 10px 10px;
            margin: 10px auto;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            transition: background-color 0.3s, transform 0.2s;

            &:hover,
            &:focus {
                background-color: #fa8211;
                transform: translateY(-3px);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
            }

            &:active {
                background-color: #f47600;
                transform: translateY(1px);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
            }
        }

        .preview {
            margin: 2rem 0;
            height: 55rem;

            .scenes {
                margin-top: 2rem;
                border: 1px solid var(--gray-8);
                border-radius: .6rem;
                padding: .3rem;
                max-height: 40rem;
            }
        }

        .avatar {
            position: absolute;
            top: 0;
            /* Position at the bottom */
            left: 0;
            /* Position at the left */
            padding: 30px;
            /* Adjust padding as needed */
            z-index: 10;
            /* Ensure the avatar is above other content */
        }

        .avatar img {
            width: 100px;
            /* Adjust size as needed */
            height: 100px;
            /* Maintain aspect ratio */
        }
    }


    .contnetActions {
        width: 44rem;

        .actions {
            display: grid;
            justify-content: right;
            grid-auto-flow: column;
            gap: 1rem;
            opacity: 1;
        }

        &:hover {
            .actions {
                opacity: 1;
            }
        }

        textarea.textArea {
            margin-top: 2rem;
            height: 15rem;
        }

        .audioText {
            margin-top: 2rem;
            width: calc(100% - 4rem);
        }

        .videoText {
            margin-top: 2rem;
            width: calc(100% - 4rem);
        }

        .audioSelect {
            padding: 2rem 0;
            width: calc(100% - 4rem);
        }

        .tabs {
            padding: 2rem 0;
        }
    }
}

.backgroundWrapper {
    padding: 2rem;
    background: var(--gray-1);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    // max-height: 40rem;
    max-height: 26rem;
    overflow: scroll;
    overflow-x: hidden;
    // overflow: auto;
    position: relative;

    .uploadIcon {
        position: relative;
        color: #49be25;
        bottom: 1rem;
        width: 4rem;
        height: 4rem;
        right: 1rem;
    }

    .bgCard {
        height: 10rem;

        // background: var(--gray-10);
        &.selected {
            border-color: #1890ff;
            /* Highlight color for selected image */
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.89);
            /* Optional shadow for emphasis */
            transform: scale(1.10);
            /* Slightly enlarge the selected image */
        }

        img {
            height: 10rem;
            width: 12rem;
        }
    }

    .gcse-search {
        width: 100%;
        /* Full width of the parent container */
        max-width: 100%;
        /* Prevent overflow */
        box-sizing: border-box;
        /* Include padding and borders in the element's total width */
    }

    .searchResults {
        margin-top: 30px;
        max-height: 0rem;
        /* Limit height for scrolling */
        overflow-y: auto;
        /* Enable vertical scrolling */
    }
}

.active {
    border: 1px solid var(--gray-8) !important;
}

.audioWrapper {
    padding: 2rem;
    background: var(--gray-1);
    max-height: 40rem;
    overflow: hidden;

    .bgCard {
        height: 6;
        display: grid;
        grid-template-columns: 1fr 5rem;
        align-items: center;
        margin-bottom: 1rem;

        .itemInfo {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            justify-content: left;
            max-width: 30rem;
        }

        img {
            height: 4rem;
            width: 4rem;
            border-radius: .4rem;
        }
    }
}

.contentWrapper {
    padding: 2rem;
    background: var(--gray-1);
    max-height: 45rem;
    overflow: hidden;

    .bgCard {
        height: 6;
        display: grid;
        grid-template-columns: 1fr 5rem;
        align-items: center;
        margin-bottom: 1rem;

        .itemInfo {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            justify-content: left;
            max-width: 30rem;
        }

        img {
            height: 4rem;
            width: 4rem;
            border-radius: .4rem;
        }
    }
}

.editTextContainer {
    max-width: 600px;
    margin: 0 auto;
}

.option {
    margin-bottom: 15px;
}

.backgroundWrappers {
    padding: 2rem;
    background: var(--gray-1);
    display: grid;
    gap: 2rem;
    max-height: 26rem;
    overflow: scroll;
    overflow-x: hidden;
    overflow: auto;
    position: relative;
}



.avatarCard {
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}

.avatarImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;

    .avatarCard:hover & {
        transform: scale(1.05);
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;

    .avatarCard:hover & {
        opacity: 1;
    }
}

.iconWrapper {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: 28px;
    color: #000;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.2);
    }
}

.bgCard {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &.selectedAvatar {
        border: 3px solid #fa8211;
        border-radius: 10%;
        transform: scale(1.1);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    .selectionOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(76, 175, 80, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;

        .checkIcon {
            font-size: 2rem;
            color: #ffffff;
        }
    }

    &.selectedAvatar .selectionOverlay {
        opacity: 1;
        pointer-events: auto;
    }
}

