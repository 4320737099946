.wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
  &::before {
    background-image: url("../../../../public/images/bg1blur.png");
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    opacity: 0.24;
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .card {
    box-shadow: var(--card-4);
    width: 35rem;
    height: max-content;
    background: var(--white);
    z-index: 1;
    border-radius: 0.6rem;
    padding: 2rem  2rem 3rem;
    display: grid;
    align-items: center;
    .logo{
      display: grid;
      gap: .3rem;
      padding-bottom: 1rem;
      .title{
        text-align: left;
        margin-top: 1rem !important;
      }
      .desc{
        text-align: center;
        color: var(--gray-8);
        font-size: 1.1rem;
      }
    }
    .footer{
      padding-top: 1rem;
      display: grid;
      grid-auto-flow: row;
      gap: 1rem;
    }
    .otherLogin{
      display: grid;
      gap: 1rem;
      padding-top: 1rem;
      .gbtn{
        background: #F3F4F6;
      }
      .link{
        color: var(--color-palette-4-dark);
        font-size: 1.2rem;
      }
    }
  }
}
